<template>
  <div>
    <Title :loading="loading">{{ $t("chatSidebar.other") }}</Title>
    <div class="chatWrapp">
      <Message
        v-for="message in conversations"
        :key="message.id"
        :message="message"
        @open="openMsg"
      ></Message>
      <div v-if="load" v-intersect="getConversations" class="text-center mt-2">
        <v-progress-circular
          :width="3"
          :size="25"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
    </div>
  </div>
</template>
<script>
import * as types from '@/store/modules/chat/chat-mutation-types'
import Message from '../Shared/Message.vue'
import Title from '../Shared/Title.vue'
import * as list from '@/types/chat-list'
import { markAsRead } from '@/signalr/chat.js'
// import * as thread from '../../types/thread-type.js'
export default {
  name: 'Other',
  components: { Message, Title },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    conversations () {
      return this.$store.getters['chat/otherMessages']
    },
    load () {
      return this.$store.getters['chat/loadOther']
    }
  },
  async created () {
    this.$store.commit(`chat/${types.CLEAR_OTHER}`)
    this.$store.commit(`chat/${types.CLEAR_NEW}`, list.newOther)
    await this.getConversations()
  },
  methods: {
    async getConversations () {
      this.loading = true

      await this.$store.dispatch('chat/getOtherMessages')

      this.loading = false
    },
    openMsg (message) {
      const found = this.conversations.find((msg) => msg.conversationId === message.conversationId && msg.threadId === message.threadId)
      if (!found) return
      if (!found.readByCurrentUser) {
        found.readByCurrentUser = true
        markAsRead(found.threadId, found.messageId)
      }
      this.$store.dispatch('chat/openConversation', { conversationId: found.conversationId, threadId: found.threadId })
    }
  }
}
</script>
